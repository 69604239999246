import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
import CustomCard from '../components/CustomCard/CustomCard.js';
import { OvalDecor, RectangleDecor } from '../components/decors.js';
import '../styles/custom-card-group.scss';
import { withPrefix } from 'gatsby-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <div className="custom-card-group decor-animation-trigger">
  <div className="custom-card-group__third collapse-on-mobile">
    <CustomCard title="APIs" decorator content={<OvalDecor mdxType="OvalDecor" />} mdxType="CustomCard"></CustomCard>
  </div>
  <div>
    <div className="custom-card-group__half-height">
      <div className="custom-card-group__flex-helper">
        <CustomCard title="Channel API" href={withPrefix('/channel-api-getting-started')} content="Control IBM Video Streaming organizations, accounts, channels and videos. Build a full white-label streaming platform or create multiple channels under a single account." mdxType="CustomCard"></CustomCard>
      </div>
      <div className="custom-card-group__flex-helper">
        <CustomCard title="Viewer Authentication API" href={withPrefix('/viewer-authentication-api-v2-getting-started')} content="Connect IBM Video Streaming to your own user database or directory information service and manage access control using your existing security tools for both live and recorded content." mdxType="CustomCard"></CustomCard>
      </div>
    </div>
    <div className="custom-card-group__half-height">
      <div className="custom-card-group__flex-helper">
        <CustomCard title="Player API" href={withPrefix('/player-api-getting-started')} content="Manage the features of the IBM Video Streaming player and customize its layout, while maintaining a continuous, interactive data connection with the IBM Video Streaming backend." mdxType="CustomCard"></CustomCard>
      </div>
      <div className="custom-card-group__flex-helper">
        <CustomCard title="Analytics API" href={withPrefix('/analytics-api-getting-started')} content="Tap into the engagement and telemetry data of IBM Video Streaming. Create filters for live and VOD (video-on-demand) un-sampled data and get a customized data feed for further processing." mdxType="CustomCard"></CustomCard>
      </div>
    </div>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      